<template>
	<el-main v-loading="isLoading">
		<div class="flex justify-center">
			<el-card shadow="always" class="w-1/2">
				<el-form
						ref="change-password-form"
						:model="form"
						:rules="rules"
						@keyup.enter.native="onSave"
						class="-mx-2"
				>
					<div class="w-full flex flex-wrap">
						<!--Actual password input-->
						<el-form-item
								label="Password attuale"
								prop="oldPassword"
								class="w-full"
						>
							<el-input
									v-model="form.oldPassword"
									show-password
							/>
						</el-form-item>
						<!--New password input-->
						<el-form-item
								label="Nuova Password"
								prop="newPassword"
								class="w-full"
						>
							<el-input
									v-model="form.newPassword"
									show-password
							/>
						</el-form-item>
						<!--Password confirmation input-->
						<el-form-item
								label="Conferma Password"
								prop="newPasswordConfirm"
								class="w-full"
						>
							<el-input
									v-model="form.newPasswordConfirm"
									show-password
							/>
						</el-form-item>
					</div>
					<div class="w-full flex justify-end mt-2">
						<el-form-item>
							<el-button
									@click.native.prevent="onSave"
									type="success"
									icon="el-icon-check"
									plain
									size="large"
							>
								Salva
							</el-button>
						</el-form-item>
						<el-form-item class="pl-5">
							<el-button
									@click.native.prevent="onBack()"
									type="info"
									plain
									size="large"
							>
								Esci
							</el-button>
						</el-form-item>
					</div>
				</el-form>
			</el-card>
		</div>
	</el-main>
</template>

<script>
import authService from '@/modules/auth/services/auth.services';

export default {
	name: 'ChangePassword',
	data() {
		/**
		 * @desc New password validator
		 * @param rule
		 * @param value
		 * @param callback
		 */
		const validateNewPassword = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('Il campo è obbligatorio'));
			}
			if (value === this.form.oldPassword) {
				callback(new Error('La nuova password deve essere differente da quella attuale'));
			}
			if (value.length < 6) {
				callback(new Error('La password deve essere di almeno 6 caratteri'));
			}
			callback();
		};
		/**
		 * @desc Password confirmation validator
		 * @param rule
		 * @param value
		 * @param callback
		 */
		const validateNewPasswordConfirm = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('Il campo è obbligatorio'));
			}
			if (value !== this.form.newPassword) {
				callback(new Error('La password non corrisponde a quella nuova'));
			}
			callback();
		};
		return {
			form: {
				oldPassword: '',
				newPassword: '',
				newPasswordConfirm: '',
			},
			rules: {
				oldPassword: [
					{ required: true, message: 'Il campo è obbligatorio', trigger: 'change' },
				],
				newPassword: [
					{ validator: validateNewPassword, required: true, trigger: 'change' },
				],
				newPasswordConfirm: [
					{ validator: validateNewPasswordConfirm, required: true, trigger: 'change' },
				],
			},
			isLoading: false,
		};
	},
	methods: {
		/**
		 * @desc Save new password
		 */
		async onSave() {
			try {
				await this.$refs['change-password-form'].validate();
			}
			catch (error) {
				return;
			}
			this.isLoading = true;
			try {
				await authService.changePassword(this.form);
				this.$message.success('Password aggiornata con successo');
				this.onBack();
			}
			catch (error) {
				this.$message.error('Si è verificato un errore nel cambio password!');
			}
			finally {
				this.isLoading = false;
			}
		},
		/**
		 * @desc Go back with router
		 */
		onBack() {
			this.$router.push('/projects')
		},
	},
};
</script>

<style scoped>

</style>
